.cookie-policy-popup{
    &.content{
        max-width:50rem;
    }
    button{
        &.primary{
            height:4rem;
            width:100%;
            margin-top:3rem;
        }
    }
}