@import './product-list';
@import '../variables';

.quick-search-results {
  position: absolute;
  z-index: 7;
  background: #fff;
  width: 40.5%;
  padding: 1rem 1.5rem;
  margin: .5rem auto;
  // left: 27.5%;
  // top: 130px;
  top: 32px;
  left: 0;
  border: 1px solid @tertiary;
  border-radius: 5px;
  width: 100%;

  .results {
    display: flex;
    min-height: 3.5rem;
    margin: .5rem 0;
    justify-content: space-between;
    text-align: start;

    .results-found {
      .results-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          color: @primary;
          font-size: 1.8rem;
          text-align: left;
        }
        a {
          text-align: right;
        }
      }
    }

    .no-results-found {
      width: 100%;

      h1 {
        text-align: center;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        text-align: center;
        border-right: 1px solid #A9A9A9;
        margin-right: -4px;
        margin: 1rem 0;
        flex-basis: 33.333%;
        max-width: 33.333%;
        display: flex;

        &:last-of-type,
        &:nth-child(3n) {
          border-right: none;
        }

        article {
          flex: 1;
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          flex-flow: column;
          align-items: center;

          header {
            h1 {
              font-size: @large-font-size;
              color: @default-font-colour;
            }
          }

          p {
            font-size: @small-font-size;
            margin-top: 15px;
          }

          .button {
            color: #fff;
            margin-top: 15px;
            height: 28px;
            width: 100%;
          }

          .image-container {
            margin-top: 15px;
            img {
              width: auto;
              max-height: 7rem;
            }
          }
        }
      }
    }

    section {
      width: 100%;

      &.results-loading {
        position: relative;
        text-align: center;

        .spin-animation {
          margin-right: 0rem;
          width: 130px;
          height: 130px;
          border: 10px solid #f3f3f3;
          border-top: 12px solid #38A635;
          border-radius: 50%;
          margin: 0 auto;
          animation: spin 1000ms infinite linear;
        }

        /* Animation */
        @-webkit-keyframes spin {
          to { -webkit-transform: rotate(360deg); } }
        @-moz-keyframes spin {
          to { -moz-transform: rotate(360deg); } }

        img { 
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          transform: translateY(-9rem);
        }
      }

      h1 {
        text-align: center;
      }
    }
  }
}

@media @laptop {
  .quick-search-results .results ul li article .image-container {
    min-height: 9.5rem;
    min-width: 9.5rem;
  }         
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
}

.fallback-rules {
  .quick-search-results .results ul li article p {
    max-width: 100%;
  }
}