@import 'variables';
@import 'containers/footer';
@import 'fonts/font';
@import "fonts/solid";
@import 'containers/header';
@import 'containers/nav';
@import 'containers/breadcrumb';
@import 'components/button';
@import 'components/message-toast';
@import 'containers/date-picker';
@import 'containers/search';
@import 'components/popup';
@import 'components/compare';
@import 'components/cookie-policy';
@import 'containers/product-type-warning-popup';
@import './macros';

html {
  font-size: 62.5%;
}

/*.lazy-image {
  opacity: 0;
}

.lazy-image-loaded {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}*/

.loading-container {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-background{
    position: absolute;
    left: 0;
    top: 0;
  
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: @nothing-to-display-text;
  
    z-index: 2;
  }
  .results-loading {
    margin: 0 auto;
    z-index: 10;
    .spin-animation {
      margin-right: 0rem;
      width: 130px;
      height: 130px;
      border: 10px solid #f3f3f3;
      border-top: 12px solid #38A635;
      border-radius: 50%;
      margin: 0 auto;
      animation: spin 1000ms infinite linear;
    }
  
    /* Animation */
    @-webkit-keyframes spin {
      to { -webkit-transform: rotate(360deg); } }
    @-moz-keyframes spin {
      to { -moz-transform: rotate(360deg); } }
  
    img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-9rem);
    }
  }
}

html,
body {
  min-height: 100%;
  height: 100%;
  //overflow: overlay;
  //align-content: space-between;
}

body {
  font-family: @font-family;
  color: #363636;
  margin: 0;
  padding: 0;
  // display: grid;
  // grid-gap: 30px;
  height: 100%;
  font-size: 14px;
  //grid-template-rows: 95px 50px 30px 1fr 335px;  
  &.ajax-wait {
    cursor: wait;
  }
  main {
    padding-bottom: 25px;
    min-height: 37.5%;
    &.basic-page {
      min-height: 36.5vh;
    }
  }
}

.modal .scanner-popup {
  max-width: 600px;
  // height: 64%;
  video{
    width:100%;
  }
  .drawingBuffer{
    position: absolute;
    left:0;
    height:100%;
    width:100%;
    top:0;
    z-index:-1;
  }
  button:not(.close){
    width:100%;
    height:40px;
    margin-top:2rem;
  }
  .scanner-loading{
    &:not(.hidden){
      + video{
        display:none;
      }
    }
    min-height: 500px;
    min-width: 600px;
  }
  .upload{
    // margin-top:2rem;
    .button{
      width: 100%;
      line-height: 4rem;
      font-weight: 300;
      margin-top:1rem;
    }
  }
  // [data-component="scanner"]{
  //   &:before{
  //     content:'Loading....';
  //   }
  // }
}

.modal {
  .confirm-address-change{
    width:55%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    p{
      text-align: center;
      margin-bottom: 20px;
    }
    .popup-buttons{
      display: flex;
      flex-flow: row;
      justify-content: space-around;
      margin: 0 20%;
      button{
        width: 30%;
        min-width: 115px;
        height: 40px;
      }
    }
  }
  .confirm-delete-list{
    width:55%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    p{
      text-align: center;
      margin-bottom: 20px;
    }
    .popup-buttons{
      display: flex;
      flex-flow: row;
      justify-content: space-around;
      margin: 0 20%;
      button{
        width: 30%;
        min-width: 115px;
        height: 40px;
      }
    }
  }
}

.ajax-page-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  // background: rgba(0,0,0,0.2);
  z-index: 2;
}

p {
  line-height: 17px;
}

ul {
  list-style-type: none;
}

h1 {
  color: @primary;
  font-size: @h1-font-size;
}

h2 {
  margin-bottom: 5px;
  font-size: 20px;
}

h3 {
  color: @primary-lighter;
  margin-bottom: 10px;
}

.inner-wrapper {
  max-width: 1230px;
  margin: 0 auto;
  width: 100%;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.hidden {
  display: none !important;
  // visibility: hidden;
}

a {
  text-decoration: none;
  color: @cta-primary;
  &.opening-new-tab {
    position: relative;
    &:after {
      content: '\f35d';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      right: -19px;
      top: 0;
    }
  }
}

input {
  padding-left: 5px;
  border:1px solid @tertiary;
  border-radius:5px;
  &[type="checkbox"]{
    width:20px;
    height:20px;
  }
}


select{
  padding:10px 20px 10px 10px;
  // -webkit-appearance: none;
  -webkit-border-radius: 0px;
  // -moz-appearance: none;
  // appearance: none;
  // background: url('http://placehold.it/10x10') no-repeat 97% 50% #ffffff; //can't use psuedo on select - wrap in label?
  background-size: 11px 7px;
  border:1px solid @tertiary;
  border-radius:5px;
  height:40px;
}
.custom-select{
  height:40px;
  display:inline-block;
  position: relative;
  &:after {
    content:"\f078";
    font-family:'Font Awesome 5 Free';
    position: absolute;
    font-weight:900;
    font-size:1.5rem;
    top: 20px;
    right: 10px;
    z-index: 5;
    pointer-events: none;
    color:@select-dropdown;
  }
  select{
    margin-top: 1px;
    padding:5px 23px 5px 10px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    -moz-appearance: none;
    appearance: none;
    background: #FFFFFF;
    background-size: 11px 7px;
    border:1px solid @tertiary;
    border-radius:5px;
    height:90%;
    width:100%;
    &::-ms-expand {
      display: none;
    }
    option:last-child{
      background-color: @select-contact-us;
    }
  }
}

label{
  &.custom-radio{
    // text-indent: 30px;
    padding-left:30px;
    position: relative;
    input[type='radio']{
      display:none;
    }
    &:before {
      content: ' ';
      border-width: 0px;
      position: absolute;
      left: 0;
      top: -3px;
      width: 20px;
      height: 20px;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 2px;
      border-style: solid;
      border-color: @tertiary;
      border-radius: 100%;
      box-shadow: none;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 10px;
      color: #FFFFFF;
    }
    &.selected {
      &:after {
        content: ' ';
        border-width: 0px;
        position: absolute;
        left: 5px;
        top: 2px;
        width: 10px;
        height: 10px;
        background: inherit;
        background-color:#41AA44;
        box-sizing: border-box;
        border-width: 2px;
        border-style: solid;
        border-color: #41AA44;
        border-radius: 100%;
        box-shadow: none;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 10px;
        color: #41AA44;
        background:#41AA44        
      }
      &:before{
        border-color: #41AA44;
      }
    }
    &:hover {
      cursor: pointer;
      &:before {
        border-color: #41AA44;
      }
    }
  }
  &.custom-checkbox {
    // text-indent: 30px;
    padding-left:30px;
    position: relative;
    input[type=checkbox] {
      display: none;
    }
    &:before {
      content: ' ';
      border-width: 0px;
      position: absolute;
      left: 0;
      top: -3px;
      width: 20px;
      height: 20px;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      border-width: 2px;
      border-style: solid;
      border-color: @tertiary;
      border-radius: 2px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 10px;
      color: #FFFFFF;
    }
    &.selected {
      &:after {
        position:absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 10px;
        // border-color: #41AA44;
        content: '\f00c';
        color: #41AA44;
        font-size: 10px;
        padding: 2px;
        left:.3rem;
        top: 0;
      }
      &:before{
        border-color: #41AA44;
      }
    }
    &:hover {
      cursor: pointer;
      &:before {
        border-color: #41AA44;
      }
    }
  }
}

.empty-list {
  width: 100%;
  border: 4px dashed @tertiary-border;
  margin-top: 30px;
  text-align: center;
  padding: 59px;
  color: @nothing-to-display-text;
}

@media @laptop {
  .inner-wrapper {
    padding: 0 15px;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  img {
    max-width: 100%;
    height: inherit;
  }
  body {
    //display: inline-block;
    main {
      //grid-row: 4;
      //-ms-grid-row: 4;
      display: block;
      margin: 0;
    }
  }
}

/*.fallback-rules {
  body {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    >header,
    >nav,
    >main,
    >footer {
      width: 100%;
      margin-bottom: 10px;
    }
    main {
      //display: flex;
      //flex-wrap: wrap;
      flex: 1;
      .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
        //height: inherit;
      }
    }
  }
}*/

.mobile-show {
  visibility: hidden;
  display: none;
}
.mobile-hide {
  visibility: visible;
  display: block;
}

@media @ipad-portrait {
  .tablet-portrait-show {
    visibility: visible;
    display: block;
  }
  .tablet-portrait-hide {
    visibility: hidden;
    display: none;
  }
  .modal {
    .confirm-address-change{
      width:70%;
      .popup-buttons{
        margin: 0 5%;
        button{
          min-width: 100px;
        }
      }
    }
    .confirm-delete-list{
      width:70%;
      .popup-buttons{
        margin: 0 5%;
        button{
          min-width: 100px;
        }
      }
    }
  }
}

@media @mobile {
  .mobile-show {
    visibility: visible;
    display: block;
  }
  .mobile-hide {
    visibility: hidden;
    display: none;
  }
  .modal {
    .confirm-address-change{
      width:90%;
      .popup-buttons{
        margin: 0 5%;
        button{
          min-width: 100px;
        }
      }
    }
    .confirm-delete-list{
      width:90%;
      .popup-buttons{
        margin: 0 5%;
        button{
          min-width: 100px;
        }
      }
    }
  }
}

@media @ipad-landscape,
@ipad-portrait,
@mobile {
  .inner-wrapper {
    padding: 0 4%;
  }
}