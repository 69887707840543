@import "../variables.less";

.modal {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .content {
    background-color: #fefefe;
    margin: auto;
    padding: 30px;
    border: 1px solid #888;
    width: 40%;
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      background: transparent;
      border: none;
      color: @off-white;
      font-size: 19px;
      &:before {
        content: '\f00d';
        position: absolute;
        right: 10px;
        top: 10px;
        background: 0 0;
        border: none;
        color: @tertiary;
        font-size: 19px;
      }
      &:hover {
        color: @primary-lighter;
      }
    }
    &.rename-list{
      width: 40%;
      border-color: @primary-lighter;
      border-radius: 5px;
      .popup-container{
        p{
          text-align: center;
          margin-bottom: 2rem;
          font-weight: 700;
        }
        .popup-buttons{
          form{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            label{
              width: 70%;
              margin-right: 2rem;
              input{
                width: 100%;
                height: 4rem;
                outline: none;
                &:focus{
                  border: 1px solid @primary-lighter;
                  box-shadow: 0 0 2px @primary;
                }
              }
            }
            button{
              width: 30%;
              height: 40px;
            }
          }
        }
      }
    }
  }
}

button.fas.close {
  width: 3rem;
  height: 3rem;
}

@media @ipad-landscape {
  .modal {
    .content {
      width: 90%;
      &.rename-list{
        width: 70%;
      }
    }
  }
}

@media @ipad-portrait {
  .modal {
    .content {
      width: 90%;
      &.rename-list{
        width: 70%;
      }
    }
  }
}

@media @mobile {
  .modal {
    .content {
      width: 90%;
      &.rename-list{
        width: 90%;
      }
    }
  }
}