@import '../variables';

.breadcrumb {
  //grid-area: breadcrumb;
  margin: 1rem 0rem 1.5rem;
  // margin-top: 30px;
  .inner-wrapper {
    border-bottom: none;
    //margin-right: 325px;
    ol {
      display: flex;
      list-style-type: none;
      flex-wrap: wrap;
      li {
        position: relative;
        line-height: 1.5;
        &:not(:last-of-type) {
          margin-right: 20px;
          &:after {
            content: '\f054';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 1rem;
            color: #ccc;
            position: absolute;
            top: 3px;
            right: -12px;
          }
        }
        a {
          color: @primary-lighter;
        }
        &.active {
          font-weight: 900;
        }
        .breadcrumb-button {
          display: none;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: 0.5rem;
          border: 0;
          background: none;
          &:after {
            content: '\f067';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 1.8rem;
            color: @primary-lighter;
          }
        }
      }
    }
    &.no-category-header {
      padding-top: 2.5rem;
    }
  }
}

@media @laptop {
  .breadcrumb {
    margin: 1rem 0 1.5rem;
  }
}

@media @ipad-landscape {
  .breadcrumb {
    margin: 1rem 0 1.5rem;
    .inner-wrapper{
      &.no-category-header {
        padding: 2.5rem 4% 0;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  .personal-details .inner-wrapper {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
    section {
      &.change-personal-details {
        margin-right: 3%;
        -ms-grid-column: 1;
        grid-column: 1;
      }
      &.change-password {
        margin-left: 3%;
        -ms-grid-column: 2;
        grid-column: 2;
      }
    }
  }
}

@media @ipad-landscape {
  .contact-us {
    .inner-wrapper {
      flex-direction: column;
      section {
        &.get-in-touch {
          border-right: 0;
          padding-right: 0;
        }
        &.find-us {
          padding-left: 0;
          margin-top: 40px;
        }
      }
    }
  }
}

@media @ipad-portrait {
  .breadcrumb {
    margin: 1.5rem 3rem;
    .inner-wrapper {
      ol {
        flex-direction: column;
        li {
          display: none;
          padding-left: 2rem;
          &:nth-last-child(2) {
            display: block;
          }
          &.active {
            display: none;
          }
          &:not(:last-of-type) {
            // margin-right: 20px;
            &:after {
              content: '';
            }
            &:before {
              content: '\f053';
              font-family: 'Font Awesome 5 Free';
              font-weight: 900;
              font-size: 1.8rem;
              color: #ccc;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
          &.visible {
            &:not(.active) {
              display: block;
            }
          }
          .breadcrumb-button {
            display: block;
            &.active {
              &:after {
                content: '\f068';
              }
            }
          }
        }
      }
    }
  }
}

@media @mobile {
  .breadcrumb {
    margin: 1.5rem;
  }
}