@import '../variables';
@import '../components/jquery-ui.less';
@import './mobile-nav.less';
@import '../macros';

.mobile-ship-to-date-header,
.mobile-basket-component {
  display: none;
}

.user-mobile-header {
  display: none;
}

.page-header {
  .header-top {
    background:linear-gradient(3deg,#367828 17%,@dark-green 18.1%); 

    padding: .5rem 0;

    //height: 35px;
    .inner-wrapper {
      display: flex;
      //grid-template-columns: 1fr 25% 10%;
      //grid-template-columns: 1fr 1fr .5fr .3fr;
      align-items: center;

      .sales-rep {
        
        //margin-left: -12rem;
        p {
          flex: 1;
          color: #fff;
          text-align: left;
          font-size: 12px;

          span {
            // color: #fff;
            margin-right: 10px;
            //flex: 1;
          }

          .type {
            color: @primary-lighter;
            margin-right: 5px;
          }
        }
      }

      .user-functions {
        flex: 1;
        text-align: center;
        &.not-logged-in {
          text-align: right;
        }
        p { 
          font-size: 12px;
        }

        span {
          color: #fff;
        }
        .logged-in-customer{
          border-right: 1px solid @primary-lighter;
          margin-right: 10px;
          padding-right: 10px;
        }

        a {
          color: @primary-lighter;
          &:first-child {
            margin-right: 0.5rem;
          }
          // &:last-child {
          //   margin-left: 0.5rem;
          // }
        }
      }

      .delivery-address {
        flex: .5;
        position: relative;

        // select {
        //   width: 80%;
        //   height: 35px;
        // }
        .custom-select {
          width: 100%;
          height: 3rem;

          &:before {
            content: '\f0d1';
            font-size: 1.4rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            left: -9%;
            transform: rotateY(180deg);
            color: @primary-lighter;
            top: .8rem;
          }

          &:after {
            top: .8rem;
            right: -4.5px;
            margin-left: 10px;
          }
          select {
            padding: 0 2rem 0 1rem;
            font-size: 1.2rem;
            margin-left: 10px;
          }
        }
      }

      .delivery-date {
        flex: .3;
        position: relative;

        &:before {
          content: '\f073';
          font-size: 1.4rem;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          left: 26%;
          color: @primary-lighter;
          top: .7rem;
        }

        input {
          margin-bottom: 1px;
          margin-left: 55px;
          width: 63%;
          height: 29px;
          font-size: 1.2rem;
          text-align: center;
          padding-left: 0;
        }
      }

      div,
      form {
        text-align: right;
      }
    }
  }

  .header-bottom {
    //height: 86px;

    .inner-wrapper {
      display: flex;
      //grid-template-columns: 20% 55% 15% 10%;
      //grid-template-columns: 2fr 6fr 1.6fr 1fr;
      //height: 86px;
      align-items: center;
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;

      .logo {
        flex: .7;
        margin-right: 4rem;
        max-width: 20rem;
      }

      form {
        max-width: 485px;
        height: 38px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        position: relative;
        flex: 2;

        input {
          width: 100%;
          height: 100%;
          padding-left: 10px;
          &::-ms-clear {
            display: none;
          }
        }

        // &:after {
        //   content: '\f002';
        //   font-size: 1.9rem;
        //   font-family: 'Font Awesome 5 Free';
        //   font-weight: 900;
        //   position: absolute;
        //   right: 1rem;
        //   top: 9px;
        //   color: @primary-lighter;
        // }
        button[name="search"]{
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          width: 4rem;
          background:none;
          border:0;
          &:after{
            content: '\f002';
            font-size: 1.9rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: @primary-lighter;
          }
        }

        button[name="scan-ean"] {
          position: absolute;
          right: 4rem;
          top: 9px;
          background: none;
          border: 0;
          border-right: 1px solid @tertiary;
          border-radius: 0;
          padding-right: 14px;

          &:after {
            content: '\f030';
            font-size: 1.9rem;
            color: @tertiary;
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
          }
        }
      }

      .dropdown {
        flex: .65;  
        position: relative;        
        >a {
          cursor: pointer;
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
          display: flex;
          color: #000;
          line-height: 0;
          position: absolute;
          padding: 1rem;
          top: -.8rem;
          left: 2.8rem;
          &:after {
            cursor: pointer;
            content: '\f105';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 2.5rem;
            padding-left: 1rem;
            color: @primary-lighter;
          }
        }

        &.disabled {
          visibility: hidden;
        }


        .dropdown-menu {
          position: absolute;
          z-index: 99999999999;
          width: 100%;
          background-color: #fff;
          top: 7rem;
          border: 2px solid @primary-lighter;
          border-radius: 5px;
          padding: 4rem 0;
          opacity: 1;
          margin: 0 auto;
          left: 0;
          right: 0;

          ul {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            vertical-align: top;
            text-align: left;
            font-size: 14px;

            li {
              flex: 1;
              margin-right: 5px;
              flex-basis: 100px;
              text-align: center;
              margin-top: 1rem;

              a {
                color: @primary;

                &:hover {
                  color: @primary-lighter;
                }
              }
            }
          }
        }
      }

      .dropdown {
        .dropdown-menu {
          display: none;
        }

        // &:before {
        //   content: '';
        //   display: block;
        //   top: 100%;
        //   position: absolute;
        //   height: 1.5rem;
        // }

        &:hover {
          .dropdown-menu {
            display: block;
          }
        }

        .dropdown-menu {
          &:hover {
            display: block;
          }
        }
      }
      .basket-container-css
    }
  }
}

// MIGHT NEED REMOVING IF PADDING IS APPLIED BACK TO THE WEBPAGE

@media only screen and (max-width: 1480px) {
  .page-header .header-top .inner-wrapper .sales-rep{
    margin-left: 1rem;
  }
}
@media only screen and (max-width: 1270px) {
  .page-header .header-top .inner-wrapper{ 
    .delivery-date{
      margin-right: 27px;
    }
  }
  .user-mobile-header .inner-wrapper{
    padding: 0px 10px;
  }
  .page-header .header-bottom .inner-wrapper{
    .logo{
      margin-left: 3rem;
    }
    .basket-link .basket-container{
      margin-right: 1rem;
    }
    .basket-link .basket-container .basket-type .min-order-charge p{
      font-size: 12px;
      line-height: 12px;
    }
  }
}

// UP UNTIL HERE

@-moz-document url-prefix() {
  .page-header .header-bottom .inner-wrapper .basket-link .basket-container .total p {
    font-size: 1.3rem;
  }
}

.fallback-rules {
  .page-header {
    display: -ms-grid;
    -ms-grid-rows: 35px 1fr;
    //-ms-grid-row: 1;
    width: 100%;
    .header-bottom .inner-wrapper{ 
      .basket-link .basket-container .total p span:first-of-type {
        padding-bottom: 12px;
      }
      .basket-link.empty{
        width:121px;
    }
  }
    .header-top {
      -ms-grid-row: 1;
      width: 100%;

      .inner-wrapper {
        //display: -ms-grid;
        -ms-grid-columns: 3.7fr 1.5fr .6fr;

        .user-functions {
          grid-column: 1;
          -ms-grid-column: 1;
          padding: 8px 0;
        }

        .delivery-address {
          grid-column: 2;
          -ms-grid-column: 2;
        }

        .delivery-date {
          grid-column: 3;
          -ms-grid-column: 3;
        }
      }
    }

    .header-bottom {
      -ms-grid-row: 2;

      .inner-wrapper {
        .basket-link .basket-container .basket .icon::before {
          margin-left: -2px;
        }
        .logo {

          a {
            color: transparent;
          }
        }

        form {
        }

        .dropdown {
        }

        .basket-link {
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

@media @ipad-landscape {
  .page-header {
    .header-top {
      .inner-wrapper {
        grid-template-columns: 1.7fr 1.5fr 1fr;

        .sales-rep {
          p {
            font-size: 11px;
          }
        }

        .delivery-date {
          &:before {
            left: 18%;
          }
        }

        .delivery-address{
          &:before {
            left: -20%;
          }
        }

        .user-functions {
          font-size: 11px;
        }
      }
    }

    .header-bottom {
      .inner-wrapper {
        grid-template-columns: 2fr 3.5fr 1.6fr 1fr;

        .dropdown {
          p {
            margin: 0 15px;
          }
        }
      }
    }
  }
}

@media @mobile-header {
  .mobile-ship-to-date-header {
    display: block;
    background: @tertiary-lighter;
    border-bottom: 1px solid @primary;
    transition: height .5s ease;
    height: 0px;
    overflow: hidden;
    &.open {
      opacity: 1;
      height: 101px;
    }
    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: 30px;
      form {
        flex-basis: 48%;
        padding-left: 30px;
        position: relative;
        &:before {
          content: '\f073';
          font-size: 1.4rem;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          left: 0px;
          color: @primary-lighter;
          top: 10px;
        }
        &.delivery-address:before {
          transform: rotateY(180deg);
          content: '\f0d1';
        }
        input[name=delivery-date] {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .mobile-basket-component {
    display: block;
    background: @tertiary-lighter;
    border-bottom: 1px solid @primary;
    transition: height .5s ease;
    height: 0px;
    overflow: hidden;
    &.open {
      height: 103px;
      display: block;
      opacity: 1;
    }
    .inner-wrapper {
      display: flex;
      justify-content: center;
      padding: 12px 0;
      .basket-container-css;
      .basket-link {
        display: flex;
        justify-content: center;
        max-width: 355px;
        .basket-container {
          width: 100%;
          .basket {
            display: none;
          }
        }
        .basket-container .basket-type .basket-icons {
          height: 100%;
          display: flex;
          align-items: center;
          padding-right: 12px;
          border-right: 1px solid @tertiary;
          .basket-icon {
            margin-right: 0;
          }
          + div {
            margin-left: 20px;
            width: 100%;
          }
        } 
      }
    }
  }
  .page-header.tablet-portrait-show {
    .mobile-header;
  } 
  .page-header.tablet-portrait-hide {
    display: none;
  }
  .user-mobile-header {
    background-color: @primary;
    display: block;
    .inner-wrapper {
      color: #fff;
      display: flex;
      max-height: 30px;
      .user {
        position: relative;
        flex: 1;
        margin: 5px 0;
        &:before {
          content: '\f007';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          left: 0;
          top: 2px;
        }
        a {
          font-size: 1.4rem;
          &:first-of-type {
            margin-left: 23px;
          }
          color: @primary-lighter;
        }
        span {
          font-size: 1.4rem;
        }
        .logged-in-customer {
          padding-left: 7px;
          position: relative;
          &:after {
            content: '|';
            position: absolute;
            left: 0;
            color: @primary-lighter;
          }
        }
      }
      .order-progress-strip {
        background-color: #2CA929;
        padding: 9px 9px;
        font-size: 12px;
        line-height: 13px;
        max-height: 30px;
        padding-right: 29px;
        position: relative;
        margin-right: 15px;
        &:after {
          content: '';
          background-image: url(/images/arrow.svg);
          width: 14px;
          height: 8px;
          position: absolute;
          right: 8px;
          top: 12px;
        }
        &.open:after {
          transform: rotate(180deg);
        }
      }
      .dd-section {
        justify-content: flex-end;
        display: flex;
        position: relative;
        margin: 5px 0;
        .delivery {
          position: relative;
          width: 20px;
          margin-right: 5px;
          &:after {
            content: '\f0d1';
            font-size: 1.4rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            transform: rotateY(180deg);
            color: @primary-lighter;
            top: 4px;
          }
        }
        .delivery-date {
          position: relative;
          width: 20px;
          margin-right: 20px;
          &:after {
            content: '\f073';
            font-size: 1.4rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            left: 21%;
            color: @primary-lighter;
            top: 4px;
          }
        }
        &:after {
          content: '\f078';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          top: 4px;
        }
        &.open:after {
          content: '\f077';
        }
      }
    }
  }
}

@media @mobile {
  .user-mobile-header {
    P {
      font-size: 1rem;
    }
    .inner-wrapper .user a,
    .inner-wrapper .user span {
      font-size: 1rem;
    }
  }
  .page-header.mobile-show {
    .mobile-header;
    position: sticky;
    width: 100%;
    z-index: 999;
    top: 0;
  }
  .mobile-ship-to-date-header {

    &.open {
      height: 117px;
    }

    .inner-wrapper {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 0;
      .delivery-address {
        max-width: 100%;
        margin: 11px 0;
        .custom-select {
          width: 100%;

          &:after{
            top: 12px;
          }
        }
      }
      .delivery-date {
        max-width: 100%;
        height: 40px;
        margin-bottom: 30px;
        label {
          height: 40px;
        }
      }
    }
  }
}

.mobile-header {
  position: relative;
  // display:inline-block;
  display:block;
  visibility: visible;
  .inner-wrapper {
    grid-template-rows: 1fr;
    grid-template-columns: .75fr 2fr .5fr .5fr;
    display: grid;
    background: @tertiary-lighter;
    border-bottom: 4px solid @primary-lighter;
    padding: 20px 4%;

    .logo {
      text-align: center;
      a img{
        margin-left: -12px;
        max-width: 170px;
      }
    }
    .menu{
      margin-top: 6px;
      &:before {
        font-size: 2.1rem;
      }
    }

    .search {
      position: relative;
      &:before {
        content: '\f002';
        top: 5px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 2.1rem;
        position: absolute;
        // left: 20px;
        right: 0;
        color: @icon-gray;
        cursor: pointer;
      }
      &.open:before {
        content: '\f00d';
      }
    }

    .basket {
      position: relative;
      margin-right: -8px;
      top: 5px;
      .items-indicator-container{
        position: absolute;
        background-color: @primary-lighter;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        right: 5px;
        top: -2px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          font-size: 10px;
          color: @full-white;
        }
      }

      a {
    
        &:before {
          content: '\f291';
          margin-right: 10px;
          margin-top: 2px;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-size: 2.1rem;
          position: absolute;
          // left: 20px;
          right: 0;
          color: @cta-primary;
        }
      }
    }
  }

  .search-bar {
    display: none;

    &.open {
      display: inline-block;
    }

    position: absolute;
    left: 0;
    top: 100%;
    border-top: 1px solid #999;
    width: 100%;
    z-index: 9;

    .inner-wrapper {
      grid-template-columns: 1fr;
      background: #666666;

      form {
        position:relative;
        // &:after {
        //   content: '\f002';
        //   font-family: 'Font Awesome 5 Free';
        //   font-weight: 900;
        //   position: absolute;
        //   right: 22px;
        //   top: 28px;
        //   font-size: 2rem;
        //   color: @primary-lighter;
        // }

        input {
          width: 100%;
          height: 38px;
          position: relative;
        }
      }

      button[name="scan-ean"] {
        position: absolute;
        right: 4rem;
        top: 9px;
        background: none;
        border: 0;
        border-right: 1px solid @tertiary;
        border-radius: 0;
        padding-right: 14px;
        &:after {
          content: '\f030';
          font-size: 1.9rem;
          color: @tertiary;
          font-weight: 900;
          font-family: 'Font Awesome 5 Free';
        }
      }

      button[name="search"]{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 4rem;
        background:none;
        border:0;
        &:after{
          content: '\f002';
          font-size: 1.9rem;
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          color: @primary-lighter;
        }
      }
    }
  }
}