@import '../variables';

.modal .content.product-type-warning-popup {
  max-width: 450px;
  padding: 0;
  border-radius: 5px;
  .fas.close {
    display: none;
  }
  header {
    background-color: #124C21;
    padding: 24px 37px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    h1 {
      color: #fff;
      font-size: 26px;
    }
    .close {
      position: static;
      width: 33px;
      height: 33px;
      background-color: #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: '';
        color: #ebebeb;
        background-image: url(/images/X.svg);
        width: 15px;
        height: 15px;
        position: static;
      }
    }
  }
  .info,
  .action {
    padding: 32px;
  }
  .info {
    background-color: @tertiary;
    p {
      font-size: 16px;
      line-height: 19px;
    }
    .icons {
      .product-type-icons;
      background-color: transparent;
      padding: 0;
      flex-wrap: wrap;
      margin-top: 34px;
      .group {
        max-width: 47%;
        flex-basis: 47%;
        .icon {
          border: none;
          max-width: 47px;
          flex: 1;
        }
        p {
          flex: 1;
        }
        &:nth-child(n+3) {
          margin-top: 30px;
        }
      }
    }
  }
  .action button {
    width: 100%;
    padding: 15px 0;
    font-size: 16px;
    position: static;
    color: #fff;
    background: @cta-primary;
    &:before {
      content: none;
    }
  }
}

@media @mobile {
  .modal .content.info-popup {
    .info,
    .action {
      padding: 32px 40px;
    }
    .info .icons p {
      max-width: 64px;
    }
  }
  .modal .content.product-type-warning-popup .info p {
    font-size: 13.9px;
  }
}