@primary: #016833;
@primary-lighter: #36a839;
// @tertiary: #cccccc;
@tertiary: #ebebeb;
// @tertiary-lighter: #e4e4e4;
@tertiary-lighter: #fafafa;
@cta-primary: #f7941d;
@cta-primary-hover: #c06c05;
@cta-secondary: @primary;
@cta-secondary-hover: #016833;
@cta-delete-color: #cc0000;
@error: #e09191;
@error-border: #e72424;
@success-border: #77be4b;
@success: #eaf4e1;
@off-white: #fafafa;
@full-white: #ffffff;
@cta-disabled: #d7d7d7;
@icon-gray: #a1a1a1;
@text-gray: #666666;
@light-gray: #f8f8f8;
@default-font-colour: #363636;
@select-dropdown: #f8931d;
@select-contact-us: #fcedc2;
@lime-green: #e4f2d9;
@grass-green: #6bac3c;
@dark-green: #004c23;
@leaf-green: #307423;
@black: #000;
@status-no-block: #b3db8d;
@status-block: #fd999b;
@status-block-text: #d52027;

@tertiary-border: #f2f2f2;
@nothing-to-display-text: #999;

@small-font-size: 12px;
@standard-font-size: 14px;
@large-font-size: 20px;
@h1-font-size: 30px;
@table-font-size: @small-font-size;
@medium-font-size: 16px;
@font-family: 'Signika', sans-serif;
// @font-family: sans-serif;

@form-err-border: #ff9900;
@form-err-background: #fff7d2;

@ferts-and-controls: #864192;
@growing-media: #ba721f;
@moles: #ea560d;
@nematodes: #1b3f92;

@message-border: @form-err-border;
@message-background: @form-err-background;

@mobile-xsmall: ~"only screen and (max-width: 325px)";
@mobile-small: ~"only screen and (max-width: 425px)";
// BS - With the amount of content in the header it became really difficult to style the header with tablets in portrait that can show the site with a width between 481-600.
@mobile: ~"only screen and (max-width: 600px)";
@ipad-portrait: ~"only screen and (max-width: 768px)";
@ipad-landscape: ~"only screen and (max-width: 1024px)";
@mobile-header: ~"only screen and (max-width: 1095px)";
@laptop: ~"only screen and (max-width: 1215px)";