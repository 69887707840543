// MAP - To split
@import "../variables";
@import "../components/button";
@import "../components/step-input";
@import "../components/table";
@import "./paging.less";
@import "../macros";

.product-type-icon {
  .icon {
    display: flex;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    border: 2px solid #88BC23;
    &.gm {
      background-color: @growing-media;
    }
    &.moles {
      background-color: @moles;
    }
    &.ferts-and-controls {
      background-color: @ferts-and-controls;
    }
    &.nematodes {
      background-color: @nematodes;
    }
  }
}

h1 { 
  color: @default-font-colour;
}

.grid-view-products {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;

  .grid-product {
    padding: 10px;
    display: flex;
    flex-direction: column;
    max-width: 20%;
    min-width: 20%;
    max-height: 47rem;
    min-height: 47rem;

    .product-image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border: 1px solid rgba(228, 228, 228, 1);
      margin-bottom: 5px;
      min-width: 18rem;
      min-height: 18rem;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        height: 100%;
        padding: 1.5rem;
      }

      img {
        width: auto;
        max-height: 19rem;
      }
    }

    .matnr {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;

      .product-id {
        flex: 1;
        display: flex;
        align-items: center;

        p {
          color: #807f7f;
          margin-right: .4rem;
          text-transform: uppercase;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .SKU{
          min-width: 30px;
        }

        a {
          font-size: 12px;
          color: @primary-lighter;
        }
        a:hover {
          color: #016833;
        }
      }
    }


    .product-details {
      flex: 1;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;

      .type{
        display: block;
        min-width: 30px;
        .product-type-icon;
        margin-bottom: 5px;
        display: flex;
        margin-top: 10px;
      }
      .type .icon {
        width: 27px;
        height: 27px;
        border: none;
        &:nth-child(2) {
          margin-left: 10px;
        }
      }

      h3 {
        max-width: 200px;
        height: 4rem;
        overflow: hidden;
        color: @default-font-colour;
        margin-top: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        a{
          color: @primary-lighter;

        }
        a:hover {
          color: #016833;
        }

      }

      .grid-description {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 5rem;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: @standard-font-size;
        color: #807f7f 
      }

    }

    .product-pricing {
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      margin-top: 1rem;

      h1 {
        margin-right: 5px;
        color: @primary;
      }

      p{
        font-size: 14px;
      }
    }

    .product-actions {
      display: flex;
      form {
        flex:1;
        display: flex;
        flex-flow: column;
        .custom-checkbox{
          margin: 10px 0;
        }
        div{
          display: flex;
        }
        label {
          flex: 2.8;
          margin-right: 5px;
          display: flex;
          align-items: center;
          input[type="checkbox"]{
            margin-right: 10px;
          }
          span{
            width: 100%;
            input.step-input.ui-spinner-input{
              min-width: 100%;
            }
          }
        }
        button {
          flex: 1.2;
        }
      }
    }
  }
}

.product-list-setting {
  display: flex;
  background-color: rgba(250, 250, 250, 1);
  height: 60px;
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  margin: 10px 0;
  padding: 10px;

  .material-add-compare {
    flex: 2;
  }

  .material-images {
    flex: 6;
  }

  .view-settings {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    border-left: 1px solid rgba(228, 228, 228, 1);

    .grid-view {
      flex: 1;
      display: flex;
      padding: 10px;
      max-width: 40px;
      height: 40px;
      text-align: center;
      color: #999999;
      background-color: white;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      align-items: center;
      -webkit-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;

      &:before {
        flex: 1;
        content: "\f00a";
        font-size: 15px;
      }

      &:hover {
        background-color: @primary-lighter;
        color: @off-white;
        cursor: pointer;
      }

      &.active {
        background-color: @primary-lighter;
        color: @off-white;
      }
    }

    .list-view {
      flex: 1;
      display: flex;
      padding: 10px;
      max-width: 40px;
      height: 40px;
      text-align: center;
      color: #999999;
      background-color: white;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      align-items: center;
      -webkit-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;

      &:before {
        flex: 1;
        content: "\f00b";
        font-size: 15px;
      }

      &:hover {
        background-color: @primary-lighter;
        color: @off-white;
        cursor: pointer;
      }

      &.active {
        background-color: @primary-lighter;
        color: @off-white;
      }
    }
  }
}

.category-top-section {
  display: flex;

  h1 {
    color: @black;
    flex: 1;
    margin-bottom: 20px;
  }
}

.product-type-icons-info {
  flex: 4;
  display: flex;
  margin-bottom: 20px;
  border-radius: 5px;
  color: @tertiary;
  position: relative;
  flex-direction: column;

  .icons {
    .product-type-icons;
  }

  .text {
    flex: 6.5;
    padding: 10px 30px;
    background-color: #36A335;
    border-left: 2px solid @dark-green;
    border-radius: 0 0px 5px 5px;
    border-bottom: 2px solid @dark-green;
    border-right: 2px solid @dark-green;
    font-size: 1.3rem;
    line-height: 15px;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    border: none;
    color: @off-white;
    font-size: 19px;

    &:before {
      content: "\f00d";
      position: absolute;
      right: -1rem;
      top: -1rem;
      font-size: 1.5rem;
      color: #016833;
      border: 1px solid #016833;
      border-radius: 100%;
      width: 2.1rem;
      height: 2.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }

    &:hover {
      color: @tertiary-lighter;
    }
  }
}

.products-container {
  display: flex;
  justify-content: space-between;

  .filters {
    flex: 0.2;
    margin-right: 4%;

    h1 {
      margin-bottom: 60px;
    }

    .filter-section {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      h1 {
        font-size: @standard-font-size;
        color: @default-font-colour;
        font-weight: 700;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid @tertiary;
      }

      li {
        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }
    }

    .clear-filters {
      width: 100%;
      height: 4rem;
      margin-top: 2rem;
    }
  }

  .products {
    flex: 1;

    .product-list {
      tr {
        .table-check{
          padding-left:30px;
          position: relative;
          input[type=checkbox] {
            display: none;
          }
          &:before {
            content: ' ';
            border-width: 0px;
            position: absolute;
            left: 5px;
            top: 20px;
            width: 20px;
            height: 20px;
            background: inherit;
            background-color: @full-white;
            box-sizing: border-box;
            border-width: 2px;
            border-style: solid;
            border-color: @icon-gray;
            border-radius: 2px;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: 10px;
            color: #FFFFFF;
          }
          &.selected {
            &:after {
              position:absolute;
              font-family: 'Font Awesome 5 Free';
              font-weight: 900;
              font-size: 10px;
              // border-color: #41AA44;
              content: '\f00c';
              color: @primary-lighter;
              font-size: 10px;
              padding: 2px;
              left:8px;
              top: 23px;
            }
            &:before{
              border-color: @primary-lighter;
            }
          }
          &:hover {
            &:before {
              cursor: pointer;
              border-color: @primary-lighter;
            }
          }
          &.header-check{
            &:before{
              top: 5px;
            }
            &.selected {
              &:after {
                left:8px;
                top: 8px;
              }
              &:before{
                border-color: @primary-lighter;
              }
            }
          }
        }

        .image {
          max-width: 20%;
          min-width: 14rem;
          text-align: center;

          img {
            max-height: 60px;
          }
        }

        .matnr {
          width: 10%;
        }

        .description {
          width: 30%;
        }

        .unit-price {
          width: 10%;
        }

        .uom {
          width: 10%;
        }

        .qty {
          width: 15%;
          text-align: center;
        }

        .price {
          width: 10%;
        }

        .action {
          width: 8%;
        }
      }

      .step-input {
        height: 40px;
      }

      &.my-list .description {
        width: 71%;
      }

      &.my-list .image img {
        min-height: 43px;
      }

      &.my-list .matnr {
        width: 12%;
      }
    }
  }
}

form[name="products-list"] table .image {
  .product-type-icon

}

form[name="products-list"] table .image a {
  display: flex;
  justify-content: center;
  width: 7rem;
} 
  

form[name="products-list"] table .image   .icon {
  height: 26px;
  width: 26px;
  border: none;
  margin-left: 10px;
  &:nth-child(2) {
    margin-left: 10px;
  }
}

.load-more-container {
  text-align: center;
  margin-top: 40px;

  button {
    height: 40px;
    width: 150px;
  }

  p {
    margin-bottom: 10px;

    span {
      font-weight: 900;
    }
  }
}

@media @ipad-landscape {
  .product-type-icons-info {
    position: relative;
    //margin-right: 1rem;
    top: 1rem;
    .icons {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 25px 39px;
      .group {
        flex-basis: 33%;
        &:nth-child(n+4) {
          margin-top: 20px;
        }
      }
    }
    .text{
      padding: 2.1rem 2.7rem 1.8rem 2.9rem;
      p {
        font-size: 1.2rem;
      }
    }
  }

  .products-container { 
    display: block;
    .filters {
      .clear-filters { 
        width: 95%;
        margin-left: 5%;
      }
    }
  }

  .category-top-section {
    flex-flow: column;

    .product-type-icons-info {

      .icons {
        flex-flow: column;

        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .product-list {

    // .table-check, .unit-price, .uom{
    .unit-price,
    .uom {
      display: none;
    }

    thead {
      display: none;
    }
  }
}

@supports (display:grid) {
  @product-list-grid-gap: 1.7rem;

  .grid-view-products {
    display: grid;
    grid-gap: @product-list-grid-gap;
    @supports (display:grid) {
      grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    }
    >.grid-product {
      padding: 0;
      margin: 0;
      max-width: 100%;
      max-height: 100%;

      // .product-image {
      //   a {
      //     img {
      //       padding: 0;
      //       max-width: 21rem;
      //       max-height: 21rem;
      //     }
      //   }
      // }
    }
  }
}

@media @mobile {
  .grid-view-products {
    @supports (display:grid) {
      grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr)) ;
    }
    .grid-product {
      .matnr .product-id p{
        font-size: 10px;
      }
      .product-image {
        min-width: 16rem ;
      }
    }
  }
  .category-top-section{
    flex-flow: column;
  }
  .product-type-icons-info .icons {
    padding: 25px 25px;
    .group {
      flex-basis: 43%;
      max-width: 43%;
      &:nth-child(n+3) {
        margin-top: 20px;
      }
      .icon {
        min-width: 47px;
      }
    }
  }
}

@media @mobile-xsmall {
  .grid-view-products {
    @supports (display:grid) {
      grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    }
    .grid-product {
      .matnr .product-id{ 
        p{
          font-size: 9px;
        }
        a{
          font-size: 9px;
        }
      }
      .product-image {
        min-width: 14rem;
      }
    }
  }
  .product-type-icons-info .icons .group .icon {
    min-width: 35px;
    height: 35px;
    width: 35px;
    img {
      width: 20px;
    }
  }
}

@media @ipad-landscape {}