@footer-color: #464646;
footer {
  padding-top: 20px;
  margin-top:30px;
  .leaf-border-container{
    min-height: 3.9rem;
    max-height: 3.93rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    :first-child.leaf-border {
      margin-right: 9rem;
      min-height: 3.91rem;
      max-height: 3.93rem;
      width: 100%;
      background: linear-gradient(to left,#38A635 5%, #157F35 55%,#157F35 50%, #38A635 103%);
      flex: 1;
    }
    :last-child.leaf-border {
      margin-left: 9rem;
      min-height: 3.91rem;
      max-height: 3.93rem;
      width: 100%;
      background: linear-gradient(to right,#38A635 5%, #157F35 55%,#157F35 50%, #38A635 103%);
      flex: 1;
    }
    img {
      position: absolute;
      min-height: 8.26rem;
      max-height: 8.26rem;
      }
  }
  .top {
    background-color: @tertiary;
    .inner-wrapper {
      display: block;
      padding: 30px 0;
      justify-content: space-between;
      .company-site{
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 2rem 0;
        a {
          border-right: 1px solid;
          border-color: #CECECE;
          padding: 0 1rem 0 1rem;
          color: #016833;
        }
        a:last-child {
          border-right: 0;
        }
        section {
          text-align: left;
          h1 {
            font-size: 18px;
            color: #000;
            text-transform:capitalize;
          }
          ul {
            li {
              margin-top: 10px;
              a {
                color: @primary;
                font-size: 15px;
                margin-top: 10px;
                font-weight: 900;
                text-transform: capitalize;
              }
            }
          }
        }
      }
      .social-media-container {
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        .social-media{
          display: flex;
          a {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            &:after {
              font-family: 'Font Awesome 5 Brands';
              font-size: 1.9rem;
              left: 0;
              color: @primary-lighter;
              font-size: 2rem;
            }
            &.linkedin:after {
              content: '\f0e1';
              padding-bottom: 2px;
            }
            &.facebook {
              margin: 0 30px;
              &:after {
                padding-bottom: 3px;
                content: '\f39e';
              }
            }
            &.twitter:after {
              content: '\f099';
            }
            &.youtube:after {
              content: '\f167';
            }
          }
        }
      }
      form {
        flex: 1;
        display: none;
        .custom-select {
          height: 40px;
          width: 100%;
          margin-bottom: 0;
          select {
            color: @cta-primary;
          }
        }
      }
    }
  }
  .bottom {
    position: relative;
    padding: 2.75rem 0;
    background-color: @footer-color;
    .logo {
      line-height: 0;
      img{
        max-width: 115px;
      }
    }
    .top-button {
        position: fixed;
        bottom: 8rem;
        right: 6rem;
      &:after {
        font-family: 'Font Awesome 5 Free';
        font-size: 1.9rem;
        left: 0;
        color: #77B21A;
        font-size: 2rem;
        font-weight: 900;
        background: #fff;
        border: 4px solid #77B21A;
        border-radius: 100%;
        padding: 2rem 2.1rem;
        content: '\f077';
      } 
    }
    //height: 75px;

    .inner-wrapper {
      display: block;
      text-align: center;
      .vat-no {
        color: @primary-lighter;
        font-size: 14px;
        text-align: center;
        flex: 1;
      }
      .copyright {
        color: #fff;
        text-align: right;
        flex: 1
      }
    }
  }
}
.fallback-rules {
  footer {
    .leaf-border-container{
      max-height: none;
      img {
        position: relative;
        }
      :first-child.leaf-border {
        margin-right: -3px;
      }
      :last-child.leaf-border {
        margin-left: -1px;
      }
    }
  }
}

/*.fallback-rules {
  footer {
    //grid-row: 5;
    //-ms-grid-row: 5;
    //margin-top: 20px;
    .bottom {
      .inner-wrapper {
        display: -ms-grid;
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
        .logo {
          -ms-grid-column: 1;
          grid-column: 1;
          padding: 11px 0;
        }
        form {
          -ms-grid-column: 2;
          grid-column: 2;
        }
        .vat-no {
          -ms-grid-column: 3;
          grid-column: 3;
          padding: 11px 0;
        }
        .social-media {
          -ms-grid-column: 4;
          grid-column: 4;
          padding: 11px 0;
        }
      }
    }
  }
}*/

/*.fallback-rules {
  footer {
    margin-bottom: 0 !important;
    display: flex;
    flex-wrap: wrap;
    .top {
      height: 60%;
    }
    .bottom {
      height: 40%;
      .inner-wrapper {
        display: flex;
        flex-wrap: wrap;
        .logo {
          width: 20%;
        }
        form {
          width: 25%;
        }
        .copyright {
          width: 55%;
        }
      }
    }
  }
}*/

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

@media @mobile {
  footer {
    .top {
      .inner-wrapper {
        flex-direction: column;
        align-items: center;
        >div {
          flex-direction: column;
          align-items: center;
          width: 100% !important;
          section {
            text-align: center !important;
            &:not(:first-of-type),
            &:not(:last-of-type) {
              margin: 20px 0;
            }
          }
        }
        .company-site {
          padding: 1rem;
          a {
            position: relative;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-right: 0;
            &:after {
              content: "";
              border-bottom: 1px solid #CCCCCC;
              width: 2rem;
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;
              bottom: 0;
            } 
            &:last-child:after {
              border-bottom: 0;
            }
          }
        }
        .social-media {
          padding: 1rem 0;
        }
      }
    }
    .bottom {
      padding: 1rem 0;
      .inner-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        div,
        form,
        p,
        .social-media {
          text-align: center;
          padding: 5px 0;
        }
        .top-button {
          display: none;
        }
      }
    }
    // .leaf-border {
    //   height: 30px;
    //   .indent {
    //     img { 
    //       bottom: 15px;
    //     }
    //   }
    // }
  }
}

@media @ipad-portrait {
  footer {
    .top {
      .inner-wrapper {
        padding: 30px 4%;
        section {
          text-align: center;
        }
      }
    }
  .leaf-border-container{
    img {
      min-height: 82.5px;
      max-height: 82.5px;
      }
    }
  }
}