@import '../variables';

nav {
  &:not(.breadcrumb){
    border-bottom: 1px solid @cta-disabled;
  }
  .inner-wrapper { 
    padding: 0;
    border-bottom: 4px solid #88BC23;
    ul {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 100%;
      li {
        // display: grid;
        display: flex;
        text-align: center;
        font-size: 13px;
        // flex: 1 1 auto;
        flex:1;
        min-height: 4rem;
        justify-content: center;
        align-items: inherit;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-bottom: 0.5rem;
        flex-direction:column;
        align-items:center;
        a {
          color: @primary;
          font-weight: 900;
          // max-width: 83px;
          padding: 0 2rem;
          flex: 1;
          display: flex;
          align-items:center;
          width: 100%;
          justify-content: center;
          &.active {
            color: #88BC23
          }
          span{
            display: block;
            line-height: 1;
            padding-top: 4px;
          }
        }
        &:hover { 
          background: #88BC23;
          a {
            color: white;
          }
        }
      }
    }
  }
  &.my-account {
    ul {
      li {
        a { 
          max-width: 100%;
        }
      }
    }
  }
}

@media @mobile-header {
  nav.tablet-portrait-hide {
    display: none
  }
  nav {
    &:not(.breadcrumb){
      border-bottom:none;
    }
  }
}

/* BS commented this out for the nav changes we discussed, when we're sure we wont 
be returning to this method I will delete this commented out section.  */

// @media @ipad-portrait {
//   nav {
//     //margin-top: 30px;
//     &:not(.breadcrumb) .inner-wrapper {
//       padding: 0;
//       ul {
//         li {
//           font-size: 11px;
//           margin-right: 2px;
//         }
//       }
//     }
//   }
// }

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  nav{
    .inner-wrapper{
      ul{
        height: 45px;
        li{
          height: 100%;
        }
      }
    }
  } 
  nav:not(.breadcrumb) {
    //-ms-grid-row: 2;
    //grid-row: 2;
    ul li {
      flex: auto;
    }
  }
}