.compare-component{
    // position: absolute;
    position:sticky;
    bottom:0;
    width:100%;
    z-index:10;
    &.expand{
        .show-compare{
            &:after{
                content: '\f078';
            }
            & + .compare-products-container{
                height:58vh;                
            }
        }
    }
    .show-compare{
        display:block;
        padding:1.5rem 4rem;
        border-radius:0;
        &:before {
            content: '\f24d';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            margin-left:-2rem;
        }
        &:after{            
            content: '\f077';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            margin-left:1rem;
        }
    }

    .compare-products-container{
        height:0;
        background:#fff;
        transition:height 1s ease-out;
        display:flex;
        overflow:hidden;
        &:hover{
          overflow-y:auto;
        }
        padding:0 10rem;
        .attribute-headings{
            flex:1;
            font-weight:700;
            >li{
                border-bottom:1px solid @tertiary;
                border-right:1px solid @tertiary;
                padding:1rem 2rem;
                min-height:45px;
                &:first-of-type{
                    height:23rem;
                }
            }
        }
        .products{
            flex:3;
            display:flex;
            >li{
                flex:1;
                max-width:33%;
                .product{
                    position:relative;
                    border-bottom:1px solid @tertiary;
                    text-align:center;
                    .remove{
                        position: absolute;
                        top:1rem;
                        right:1rem;
                        font-size:2rem;
                        color:red;
                        &:after{
                            content:'\f00d';
                            font-family: 'Font Awesome 5 Free';
                            font-weight: 900;
                        }
                    }
                    header{
                      height:23rem; // matching height
                      display:flex;
                      flex-direction:column;
                      justify-content: space-between;
                      align-items:center;
                      padding:1rem;
                      border-bottom:1px solid @tertiary;
                      border-right:1px solid @tertiary;
                      img{
                        margin:2rem 0;
                        max-height:10rem;
                      }
                      h1{
                        font-size:1.6rem;
                        a{
                          color:@default-font-colour;
                        }
                      }
                    }
                    .attributes{
                      li{
                        padding:1rem 0;
                        min-height:45px;
                        border-right:1px solid @tertiary;
                        &:not(:last-of-type){
                          border-bottom:1px solid @tertiary;
                        }
                        span{
                            &[data-attr-bool='X']{
                              color:green;
                              &:after{
                                content:'\f00c';
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 900;
                                position: absolute;
                              }
                            }
                            &[data-attr-bool='']{
                              color:red;
                              &:after{
                                content:'\f00d';
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 900;
                                position: absolute;
                              }
                            }
                        }
                      }
                    }
                }
            }
        }
    }
}

@media @ipad-portrait {
  .compare-component{
    display:none;
  }
}