@import '../variables';

button,
.button {
  border-radius: 3px;
  border: 1px solid;
  cursor: pointer;
  outline: none;
  text-align:center;

  &[disabled] {
    cursor: auto;
  }
 
  &.primary{
    background-color: @cta-primary;
    color: #fff;
    border-color: @cta-primary;
    font-weight: bold;
    &:hover {
      background-color: @cta-primary-hover;
      border-color: @cta-primary-hover;
    }
    &.add:after {
      content: '\f067';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
    &.del {
      color: transparent;
      background-color: transparent;
      border: 0;
        &:after {
        content: '\f2ed';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: @cta-delete-color;
      }
      &:hover{
        background-color: @cta-delete-color;
        &:after{
          color: @full-white;
        }
      }
    }
    &.inverse{
      background-color: #fff;
      color: @cta-primary;
      border-color: @cta-primary;
      font-weight:700;
      &:hover {
        background-color:@cta-primary;
        color: #fff;
      }
    }
  }

  &.delete {
    background-color: transparent;
    border-color: transparent;
    font-weight: bold;
    width: 4.1rem;
    
    &:before {
      content: '\f1f8';
      font-weight: 700;
      font-family: "Font Awesome 5 Free";
      color: @cta-delete-color;
      z-index: 1;
    }

    &:hover {
      background-color: @cta-delete-color;
      border-color: @cta-delete-color;

      &:before {
        color: #fff;
      }

    }
  }

  &.primary-grid { 
    background-color: #fff;
    color: @grass-green;
    border-color:  @grass-green;
    &:hover {
      background-color: @grass-green;
      border-color: @grass-green;
      color: #fff;
    }
  }

  &.ghost {
    background: #fff;
    border: none;
    color: @cta-disabled;
    pointer-events:none;
    cursor:default;
    &:before {
      color: @cta-disabled;
    }
    &.active {
      color: @primary-lighter;
      cursor:pointer;
      pointer-events:all;
      &:before{
        color: @primary-lighter;
      }
      &:hover {
        color: @full-white;
        background-color: @primary-lighter;
        &:before {
          color: @full-white;
        }
      }
    }
    &.active-error {
      color: @error-border;
      cursor:pointer;
      pointer-events:all;
      &:before{
        color: @cta-delete-color;
      }
      &:hover {
        color: @full-white;
        &:before {
          color: @full-white;
        }
      }
    }
  }

  &.secondary{
    font-family: @font-family;
    background-color: @cta-secondary;
    color: #fff;
    border-color: @cta-secondary;
    &:hover {
      background-color: @cta-secondary-hover;
      border-color: @cta-secondary-hover;
    }    
    &.inverse{
      font-family: @font-family;
      background-color: #fff;
      color: @primary-lighter;
      border-color: @primary-lighter;
      font-weight:700;
      &:hover {
        background-color:@primary-lighter;
        color: #fff;
        background: @primary-lighter;
      }
    }
  }
  &.primary, &.secondary{
    &:disabled, &.disabled{
      background: #fff;
      border-color: @cta-disabled;
      color: @cta-disabled;  
      cursor:default;
      pointer-events: none;
      &:hover {        
        background: #fff;
        border-color: @cta-disabled;
        color: @cta-disabled;
      }
    }
  }
  &:disabled, &.disabled{
    background: #fff;
    border-color: @cta-disabled;
    color: @cta-disabled;  
    cursor:default;
    pointer-events: none;
    &:hover {        
      background: #fff;
      border-color: @cta-disabled;
      color: @cta-disabled;
    }
  }
}
.button{
  display:flex;
  align-items: center;
  justify-content: center;
}