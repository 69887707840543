@import '../variables';

.ui-widget.ui-widget-content {
  border: 1px solid @primary;
  border-radius: 5px;
  .ui-widget-header {
    background: @primary-lighter;
    span {
      color: #fff;
    }
    .ui-datepicker-prev {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        &:before {
          content: '\f053';
          position: absolute;
          top: 5px;
          right: 8px;
          color: #fff;
        }
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
      .ui-icon {
        background-image: none;
      }
      &.ui-state-hover {
        &:not(.ui-state-disabled) {
          cursor: pointer;
          &:before {
            color: @primary;
          }

        }
        background: none;
        border: none;
      }
    }
    .ui-datepicker-next {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      &:before {
        content: '\f054';
        position: absolute;
        top: 5px;
        left: 8px;
        color: #fff;
      }
  }
  }
  .ui-datepicker-calendar tbody tr td a {
    border: 1px solid @tertiary;
    color: @primary-lighter;
    &.ui-state-active {
      color: @cta-primary;
    }
    &.ui-state-hover {
      background: @cta-primary;
      color: #fff;
    }
  }
  .ui-datepicker-calendar tbody tr td span {
    border: 1px solid @tertiary;
    color: @tertiary;
  }
}

.modal .confirm-delivery-date-change{
  // width:40%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  p{
    text-align: center;
    margin-bottom: 20px;
  }
  .popup-buttons{
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    margin: 0 20%;
    button{
      width: 30%;
      min-width: 125px;
      height: 40px;
    }
  }
}