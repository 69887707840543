.menu {
  height: auto;
  margin-top: 8px;
  &:before {
    content: '\f0c9';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 2.8rem;
    cursor:pointer;
  }
  .mobile-menu {
    display: none;
  }
  &.open {
    &:before {
      
      content: '\f00d';
    }
    .mobile-menu {
      display: inline-block;
      // position: absolute;
      position:fixed;
      overflow:scroll;
      width: 100%;
      max-width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      left: 0;
      background: #666666;
      top: 8rem;
      cursor: pointer;
      // margin-top: 23px;
      z-index: 9999999999;
      height: calc(~"100% - 80px"); //subtract header - 100vh includes header therefore spills over
      li {
        width: 100%;
        text-align: left;
        // padding: 6% 2rem;
        border-bottom: 1px solid @tertiary;
        margin: 0;
        position: relative;
        &:after {
          content: '\f054';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-size: 2rem;
          position: absolute;
          right: 20px;
          top: 20px;
          color: @primary-lighter;
        }
        a {
          font-size: 16px;
          color: @off-white;
          display:block;
          padding: 5% 2rem;
        }
        &.expand{
          &:after {
            transform: rotate(90deg);
          }
        }
        .sub-menu{
          display:none;
          // margin-top:6%;
          border-top:1px solid @tertiary;
          &.open{
            display:block;
          }
          li{
            // padding:5% 1rem;
            &:after{
              font-size:2rem;
            }
            a{
              padding: 5% 3rem;
            }
          }
        }
      }
    }
  }
}