@import '../variables';
.message-toast-container { 
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 10rem;
  bottom: 0;
  padding: 2rem;
  z-index: 10;
  .message-toast {
    visibility: hidden;
    min-width: 25rem;
    background-color: #333;
    color: black;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    font-size: 17px;
    box-shadow: 3px 3px 8px #000c;
    &.show {
      visibility: visible;
      -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
      animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
    &.success {
      background-color: @success;
      border: 1px solid @success-border;
    }
    &.warning {
      background-color: @message-background;
      border: 1px solid @message-border;
      color: @default-font-colour;
    }
    &.error {
      background-color: @error;
      border: 1px solid @error-border;
    }
  }
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}