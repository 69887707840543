@import '../variables';

.step-input{
    text-align:center;
    min-width:0;
    width:100%;
  }

.ui-spinner.ui-widget.ui-widget-content {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
  height:100%;
  max-height:40px;
  border:1px solid @tertiary;
  border-radius:5px;
  .ui-spinner-input {
    border: none;
    color: inherit;
    vertical-align: middle;
    margin:0;
    min-width:130px;
    // height:100%;
    height: 40px;
    &:focus{
      outline:none;
    }
  }
  .ui-spinner-button {
    width: 1.6em;
    height: 50%;
    font-size: .5em;
    padding: 0;
    margin: 0;
    text-align: center;
    position: absolute;
    cursor: default;
    display: block;
    overflow: hidden;
    right: 0;
  }
  /* more specificity required here to override default borders */
  a.ui-spinner-button {
  border-style: none;
  height:100%;
  width:40px;
  background:#fff;
  top:0;
  cursor:pointer;
    &:after {
      color:#000;
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
      font-size: 1.5rem;
      position: absolute;
      left:0;
      right:0;
      top:50%;
      margin-top:-.8rem;
    }
  }
  .ui-spinner-up {
    right:0;
    &:after {
      content: '\f067';
    }
  }
  .ui-spinner-down {
    left:0;
    &:after {
      content: '\f068';
    }
  }
}

@media @ipad-portrait { 
  .product-details .add-to-basket-form{
    .ui-spinner.ui-widget.ui-widget-content{
      width:100%;
    }
  }

  .ui-spinner.ui-widget.ui-widget-content {
    .ui-spinner-input {
      min-width:9rem;
    }
  }
}
@media @mobile{
  .ui-spinner.ui-widget.ui-widget-content {
    width: 100%;
  }
}