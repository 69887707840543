.product-type-icons {
  flex: 1;
  padding: 10px 39px;
  background-color: @dark-green;
  border-top-left-radius: 5px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  .group {
    display: flex;
    align-items: center;
    p {
      margin-left: 9px;
      color: #88bc23;
    }
    .product-type-icon;
  }
}

.product-type-icon {
  .icon {
    display: flex;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    border: 2px solid #88bc23;
    &.gm {
      background-color: @growing-media;
    }
    &.moles {
      background-color: @moles;
    }
    &.ferts-and-controls {
      background-color: @ferts-and-controls;
    }
    &.nematodes {
      background-color: @nematodes;
    }
  }
}

.basket-container-css {
  .basket-link {
    flex: 1.3;
    color: inherit;
    // max-width: 322px;
    &.empty {
      flex: inherit;
      .total {
        padding-left: 7px;
        padding-right: 7px;
        border-left: none;
      }
    }
    .basket-container {
      border: 1px solid @tertiary;
      border-radius: 5px; //Get exact measurement
      display: flex;
      height: 78px;
      .basket {
        border-right: 1px solid @tertiary;
        padding: 0 11px;
        flex: .1;
        display: flex;
        align-items: center;
        .icon {
          // margin-bottom: 12px;
          position: relative;
          width: 23px;
          height: 19px;
          &:before {
            content: '\f291';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            font-size: 1.9rem;
            left: 0;
            color: @cta-primary;
            top: -.3rem;
          }
        }
      }
      .basket-type {
        display: flex;
        align-items: center;
        padding: 0 10px;
        flex: 1;
        border-right: 1px solid @tertiary;
        .basket-icons {
          &.multiple .basket-icon:first-of-type {
            margin-bottom: 7px;
          }
          .basket-icon {
            width: 23px;
            height: 23px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            margin-right: 10px;
            &.ferts-and-controls {
              background-color: @ferts-and-controls;
            }
            &.growing-media {
              background-color: @growing-media;
            }
            &.nematodes {
              background-color: @nematodes;
            }
            &.moles {
              background-color: @moles;
            }
          }
        }
        .order-steps,
        .order-progress-bar {
          p {
            font-size: 10px;
            margin: 0;
            line-height: 13px;
            margin: 0 auto;
          }
          .progress-bar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 90%;
            align-items: center;
            margin: 10px 0;
            position: relative;
            .bar-point-group {
              display: flex;
              align-items: center;
              position: absolute;
              left: 0%;
              //width: 100%;
              .bar {
                flex: 1;
                width: 100%;
              }
            }
            .point {
              width: 22px;
              height: 22px;
              border-radius: 100%;
              border: 1px solid #ebebeb;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
              &.active {
                background-color: @cta-primary;
                color: #fff;
              }
            }
            .bar {
              height: 4px;
              width: 100%;
              background-color: #fff;
              border: 1px solid @tertiary;
              position: relative;
              left: 15%;
              &.active {
                background-color: @cta-primary;
              }
            }
          }
        }
        .order-progress-bar .progress-bar .bar.active {
          background-color: #88bc23;
        }
        .order-progress-bar .progress-bar .point {
          position: absolute;
          transform: translateX(-22px);
          background-color: #88bc23;
          color: #fff;
          width: 24px;
          height: 24px;
          p {
            font-size: 10px;
          }
        }
        .order-progress-bar .progress-bar {
          width: 100%;
          .bar-container {
            width: 99%;
            position: relative;
            .point {
              top: -10px;
            }
            .bar {
              margin: 0 1px;
              left: 0;
              width: 99%;
              &.active {
                position: absolute;
                margin: 0 8px;
                left: 0;
              }
            }
          }
        }
        .min-order-charge,
        .order-steps {
          flex: 1;
        }
        .min-order-charge p {
          max-width: 135px;
          font-size: 13px;
          line-height: 18px;
          .price {
            font-weight: 900;
          }
        }
      }
      .total {
        flex: 1;
        max-width: 80px;
        font-size: 13px;
        color: @dark-green;
        text-align: center;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 14px;
        margin-bottom: 10px;
        p {
          // max-width: 56px;
          span {
            display: block;
            line-height: 15px;
            &:first-of-type {
              border-bottom: 1px solid @tertiary;
              padding-bottom: 15px;
            }
            &:last-child {
              padding-top: 15px;
            }
          }
        }
      }
      /*p {
        color: @primary;
  
        &.items {
          position: relative;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid @tertiary;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
  
          &:before {
            content: '\f291';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            font-size: 1.9rem;
            left: 0rem;
            color: @cta-primary;
            top: -0.3rem;
          }
        }
      }*/
    }
  }

  @media @laptop {
    .basket-link .basket-container .basket-type .order-steps .progress-bar .point {
      width: 20px;
      height: 20px;
      font-size: 13px;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .fallback-rules;
  
  }

  .fallback-rules{ 
    .basket-link .basket-container .basket-type .order-steps .progress-bar .bar-point-group {
    top: -10px;
    width: 22px;
    }
    .basket-link .basket-container{
      .basket .icon:before{
        margin-top: 1px;
      }
      .total p span:last-child {
        padding-top: 9px;
      }
      .basket-type{
        .min-order-charge p {
          max-width: 152px;
          font-size: 13px;
          line-height: 18px;
          max-height: 76px;
          width: auto;
          height: auto;
        }
      }
    }
  }
}