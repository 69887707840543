@import "../variables";

table {
  border-collapse: collapse;
  width: 100%;
  &.horizontal {
    width: 86%;
    tr {
      border-bottom: 1px solid @tertiary;
      th,
      td {
        font-size: 12px;
        text-align: left;
        padding: 10px 15px;
      }
      &.heading {
        border-top: 1px solid @tertiary;
        td {
          height: 60px;
          background-color: @off-white;
        }
      }
    }
  }
  .action button,
  .action .button {
    margin: 0.5rem;
    height: 4rem;
    min-width: 4.1rem;
    padding: 0 0.5rem;
    color: @full-white;
    &.secondary-function {
      border: none;
      background-color: @full-white;
      color: @icon-gray;
    }
  }
  &.editable {
    margin-top: 4rem;
    .heading {
      td {
        text-align: right;
      }
      .table-edit {
        height: 40px;
        width: 40px;
        border: none;
        background-color: @full-white;
      }
    }
    .table-edit {
      color: @icon-gray;
      background-color: @full-white;
      border: none;
      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
      }
      &:hover {
        background-color: @primary-lighter;
        &:after {
          color: #fff;
        }
      }
      &.edit:after {
        content: "\f304";
      }
      &.save {
        color: @primary-lighter;
        &:after {
          content: "\f00c";
        }
      }
      &.cancel {
        color: @error-border;
        &:after {
          content: "\f00d";
        }
        &:hover {
          background-color: @error-border;
        }
      }
    }
    input {
      width: 80%;
      height: 28px;
      border-radius: 5px;
      border: 1px solid @tertiary;
    }
    .new-row .table-edit.save {
      margin-right: 5px;
      position: relative;
    }
  }
  &.sortable {
    .sort {
      cursor: pointer;
      .sort-arrows {
        margin-left: 5px;
        .ascending,
        .descending {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          color: @tertiary;
        }
        .ascending {
          margin-right: 5px;
        }
      }
      &.ascending .ascending {
        color: @default-font-colour;
      }
      &.descending .descending {
        color: @default-font-colour;
      }
    }
  }
  &.standard {
    .action-header {
      border-top: 1px solid @tertiary-lighter;
      border-bottom: 1px solid @tertiary-lighter;
      height: 60px;
      border-bottom: none;
      td {
        background-color: @tertiary;
        position: relative;
        height: 60px;
        .add {
          position: absolute;
          right: 2rem;
          top: 1rem;
          width: 6rem;
          height: 4rem;
        }
      }
    }
  }
  tr {
    border-bottom: 1px solid;
    th {
      padding: 0 1.5rem;
      height: 5rem;
      text-align: left;
      font-size: @table-font-size;
      &.table-check {
        padding-left: 30px;
        position: relative;
        input[type="checkbox"] {
          display: none;
        }
        &:before {
          content: " ";
          border-width: 0px;
          position: absolute;
          left: 5px;
          // top: 50%;
          width: 20px;
          height: 20px;
          max-width: 20px;
          max-height: 20px;
          background: inherit;
          background-color: @full-white;
          box-sizing: border-box;
          border-width: 2px;
          border-style: solid;
          border-color: @icon-gray;
          border-radius: 2px;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 10px;
          color: #ffffff;
          transform: translateY(-50%);
        }
        &.selected {
          &:after {
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 10px;
            content: "\f00c";
            color: @primary-lighter;
            font-size: 10px;
            padding: 2px;
            left: 8px;
            // top: 50%;
            transform: translateY(-50%);
          }
          &:before {
            border-color: @primary-lighter;
          }
        }
        &:hover {
          &:before {
            cursor: pointer;
            border-color: @primary-lighter;
          }
          &:after {
            cursor: pointer;
            border-color: @primary-lighter;
          }
        }
        &.header-check {
          &:before {
            top: 5px;
          }
          &.selected {
            &:after {
              left: 8px;
              top: 8px;
            }
            &:before {
              border-color: @primary-lighter;
            }
          }
        }
        span {
          display: none;
        }
      }
      &.number,
      &.action {
        text-align: right;
        &.next-to-text {
          padding: 10px 15px 10px 10px;
        }
      }
    }
  }
  tbody tr {
    height: 35px;
    border-bottom: 1px solid @tertiary;
    &.edit-mode {
      background-color: @light-gray;
    }
    &:not(:first-of-type) {
      border-top: 1px solid @tertiary;
    }
    &:last-of-type {
      border-bottom: 1px solid;
    }
    td {
      // margin: .5rem;
      &.table-check {
        width: 3rem;
        padding-left: 30px;
        position: relative;
        input[type="checkbox"] {
          display: none;
        }
        &:before {
          content: " ";
          border-width: 0px;
          position: absolute;
          left: 5px;
          //top: 50%;
          width: 20px;
          height: 20px;
          max-width: 20px;
          max-height: 20px;
          background: inherit;
          background-color: @full-white;
          box-sizing: border-box;
          border-width: 2px;
          border-style: solid;
          border-color: @icon-gray;
          border-radius: 2px;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          font-size: 10px;
          color: #ffffff;
          transform: translateY(-50%);
        }
        &.selected {
          &:after {
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 10px;
            content: "\f00c";
            color: @primary-lighter;
            font-size: 10px;
            padding: 2px;
            left: 8px;
            //top: 50%;
            transform: translateY(-50%);
          }
          &:before {
            border-color: @primary-lighter;
          }
        }
        &:hover {
          &:before {
            cursor: pointer;
            border-color: @primary-lighter;
          }
          &:after {
            cursor: pointer;
          }
        }
        &.header-check {
          &:before {
            top: 5px;
          }
          &.selected {
            &:after {
              left: 8px;
              top: 8px;
            }
            &:before {
              border-color: @primary-lighter;
            }
          }
        }
      }
      &.product-status {
        max-width: 5rem;
        width: 5rem;
        .product-status-indicator {
          &:before {
            content: "\f06a";
            width: 30px;
            height: 30px;
            max-width: 30px;
            max-height: 30px;
            background-color: @full-white;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 15px;
            color: red;
          }
        }
      }
      &.number {
        text-align: right;
        padding: 0px 17px 0 5px;
      }
      &.image {
        display: flex;
        align-items: center;
        width: 16rem;
        padding: 1rem 0;
        a {
          line-height: 0;
          width: 7rem;
          display: flex;
          justify-content: center;
          img {
            max-height: 7rem;
            width: auto;
          }
        }
        .type {
          display: flex;
          min-width: 30px;
          .product-type-icon;
          margin-left: 5px;
        }
        .type .icon {
          width: 27px;
          height: 27px;
          border: none;
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
      padding: 0 1.5rem;
      font-size: @table-font-size;
      min-height: 50px;
      &.number,
      &.action {
        text-align: right;
        max-width: 12rem;
        min-width: 7rem;
      }
      &.list-name {
        max-width: 100px;
        min-width: 100px;
      }
      &.download {
        button {
          border: none;
          color: @primary-lighter;
          background-color: @full-white;
          &:hover {
            color: @primary;
          }
          &:before {
            content: "\f1c1";
            font-weight: 900;
            font-family: "Font Awesome 5 Free";
            margin-right: 5px;
          }
        }
      }
    }
  }
  a {
    color: @primary-lighter;
    &:hover {
      color: @primary;
    }
  }
}

.product-type-icon {
  .icon {
    display: flex;
    justify-content: center;
    width: 47px;
    height: 47px;
    border-radius: 100%;
    border: 2px solid #88bc23;
    &.gm {
      background-color: @growing-media;
    }
    &.moles {
      background-color: @moles;
    }
    &.ferts-and-controls {
      background-color: @ferts-and-controls;
    }
    &.nematodes {
      background-color: @nematodes;
    }
  }
}

@media @ipad-landscape {
  table {
    &.horizontal {
      width: 100%;
    }
    &.editable {
      input {
        width: 100%;
      }
    }
  }
  table.mobile-mode {
    display: block;
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead {
      tr {
        border-bottom: none;
        th {
          &:not(.action-header):not(.table-check) {
            display: none;
          }
          &.table-check {
            display: flex;
            align-items: center;
            &:before {
              margin-left: 11px;
              top:50%;
            }
            &:after {
              left: 19px;
              top: 50%;
            }
            span {
              margin-left: 10px;
              display: inline-block;
            }
          }
        }
      }
    }
    tbody {
      tr {
        height: 100%;
        border: 1px solid #eee;
        margin-bottom: 2rem;
        td {
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding: 9px 10px 10px 56%;
          min-height: 44px;
          max-width: 100%;
          word-wrap: break-word;
          overflow-wrap: break-word;
          &:last-of-type {
            border: 0;
          }
          &.table-check {
            width: 100%;
            &:after {
              left: 18px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:before {
              top: 50%;
            }
            span {
              display: initial;
            }
          }
          &.number,
          .action {
            text-align: left;
          }
          &:before {
            content: attr(data-label);
            position: absolute;
            left: 1.5rem;
            width: 40%;
            height: 100%;
            padding-right: 10px;
            font-weight: bold;
          }
          &.image {
            padding: 1rem 1.5rem;
            width: 100%;
            justify-content: flex-start;
            a {
              max-width: 50%;
              flex: 1;
            }
            img {
              margin-left: 0;
            }
          }
          &.action {
            padding: 1rem;
            display: flex;
            justify-content: center;
            button {
              width: 20rem;
              height: 4rem;
            }
          }
        }
      }
    }
    &.editable {
      .table-edit {
        &.edit {
          float: none;
        }
      }
    }
  }
}